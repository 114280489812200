<template>
  <div class="onboarding-form">
    <span v-if="showEditButton">
      <edit-2-icon
        @click="goToEdit()"
        class="float-right editIcon"
      ></edit-2-icon>
    </span>
    <!-------onboarding custom fields------>
    <div class="flex mt-6 flex-wrap" v-if="onboardingCustomFields && onboardingCustomFields.length > 0">
      <div class="input-grp mt-8" v-for="(customField,cfindex) in onboardingCustomFields" :key="cfindex">
        <label class="vs-input--label pl-0 review-label w-full">{{ customField.label }}</label>
        <p class="textBlack" v-if="customField.dataType==='currency'">
          $ {{ formatToCurrency(customField.value) }}
        </p>
        <p class="textBlack" v-else-if="customField.dataType==='number'">
          {{ formatToCurrency(customField.value) }}
        </p>
        <p class="textBlack" v-else>
          {{ customField.value }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { Edit2Icon } from "vue-feather-icons";
const tooltip = require("@/assets/images/tooltip.svg");
export default {
  components: {
    Edit2Icon,
  },
  name: "BusinessProfile",
  props: ["businessProfile", "showEditButton", "userId", "onboardingCustomFields"],

  data() {
    return {
      payload: {
        isComplete: false,
        product: "",
        productDescription: "",
        businessSummary: "",
        annualTurnoverLast12Month: 0.0,
        forecastTurnover: 0.0,
        profitLast12Month: 0.0,
        averageTransactionSize: 0,
        transactionsPerMonth: 0,
        averageDebtors: 0,
      },
      tooltip: tooltip,
    };
  },
  methods: {
    goToEdit() {
      this.$router.push({
        name: "onboarding-form",
        params: { introducerRequestedId: this.userId, card: "businessInfo" },

        query: {
          scrollTo:"businessProfile"
        }
      });
    },
    formatToCurrency(amount){
      if(!amount) return amount;
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      return formatter.format(amount).replace("$",""); 
    }
  },
  created() {
    this.payload = this.businessProfile;
  },
};
</script>
