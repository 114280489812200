<template>
  <div class="product-review">
    <vs-row v-if="product.productOption.includes('pay-now')">
      <vs-col vs-w="12" class="flex items-center">
        <div class="flex justify-center items-center" style="max-width: 20px">
          <feather-icon icon="CodepenIcon" class="text-grey-outline" />
        </div>
        <h5 class="ml-5 mb-0">Pay Now payment methods</h5>
      </vs-col>
      <vs-col vs-w="12">
        <hr class="line-hr my-2" />
      </vs-col>

      <vs-col vs-w="12" class="onboarding-form">
        <vs-row class="paymentOpt my-8" style="position: relative">
          <vs-col
            v-for="(paynowPlan, key) in product.payNowPaymentMethods"
            :key="key"
            class="w-auto"
          >
            <div class="radio-card" v-if="paynowPlan.isChecked">
              <vs-row>
                <vs-col class="flex radio-label review justify-between">
                    {{ paynowPlan.title }}
                  <S3ImageGenerator
                    :document="paynowPlan.icon"
                    :custom-class="'card-image-sm'"
                    :is-base64="false"
                  />
                </vs-col>
              </vs-row>

              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div>
                    <span class="flex items-center"
                      ><p class="text-xs">Merchant service fee:</p>
                      <h6 class="ml-2 text-xs">{{ addZeroes(parseFloat(paynowPlan.msf)) }} %</h6></span
                    >
                    <span class="flex items-center"
                      ><p class="text-xs">Surcharge to customer:</p>
                      <h6 class="ml-2 text-xs">
                        {{ addZeroes(parseFloat(paynowPlan.surcharge)) }} %
                      </h6></span
                    >
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
          <div class="absolute" style="top: 18px; right: 0px">
            <span v-if="showEditButton">
              <edit-2-icon @click="goToEditPayNow()"></edit-2-icon>
            </span>
          </div>
        </vs-row>
      </vs-col>
    </vs-row>

    <vs-row v-if="product.productOption.includes('pay-later')">
      <vs-col vs-w="12" class="flex items-center">
        <feather-icon icon="CodepenIcon" class="text-grey-outline"/>
        <h5 class="pl-4 lg:text-xl md:text-lg text-sm mb-0 font-normal">
          Pay Later products
        </h5>
      </vs-col>
      <vs-col vs-w="12">
        <hr class="line-hr mt-2 mb-4" />
      </vs-col>

      <vs-col vs-w="12" class="onboarding-form">
        <vs-row class="paymentOpt mb-8" style="position: relative">
          <vs-col
            v-for="(payLaterPlan, key) in product.payLaterProducts"
            :key="key"
            class="w-auto"
          >
            <div class="radio-card" v-if="payLaterPlan.isChecked">
              <vs-row>
                <vs-col
                  vs-w="10"
                  vs-sm="10"
                  vs-lg="10"
                  vs-xs="10"
                  class="radio-label review"
                >
                  {{ payLaterPlan.productName }}
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div>
                    <span class="flex items-center"
                      ><p class="text-xs">Service fee:</p>
                      <h6 class="ml-2 text-xs">{{ addZeroes(parseFloat(payLaterPlan.msf)) }} %</h6></span
                    >
                    <span class="flex items-center" v-if="payLaterPlan.feeModel == 'MSF_INCLUDED'"
                      ><p class="text-xs"></p>(payable by Merchant)</span
                    >
                    <span class="flex items-center" v-else
                      ><p class="text-xs">(payable by Customer)</p></span
                    >
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
          <div style="position: absolute; top: 18px; right: 0px">
            <span v-if="showEditButton">
              <edit-2-icon @click="goToEditPaylater()"></edit-2-icon>
            </span>
          </div>
        </vs-row>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { Edit2Icon } from "vue-feather-icons";
import S3ImageGenerator from "@/views/onboarding/components/S3ImageGenerator";

export default {
  components: {
    Edit2Icon,
    S3ImageGenerator
  },
  name: "ProductSelection",
  data() {
    return {
      uploadedUrl: process.env.VUE_APP_API_URL + "uploads/icons/",
    };
  },
  props: {
    product: { type: Object },
    userId: { type: String },
    showEditButton: { type: Boolean },
  },
  methods: {
    addZeroes(num) {
      num = String(num);
      const dec = num.split(".")[1];
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },
    goToEdit() {
      this.$router.push({
        name: "onboarding-form",
        params: {
          introducerRequestedId: this.userId,
          card: "productSelection",
        },
        query: {
          scrollTo: "productSelectionArea",
        },
      });
    },

    goToEditPaylater() {
      this.$router.push({
        name: "onboarding-form",
        params: {
          introducerRequestedId: this.userId,
          card: "productSelection",
        },
        query: {
          scrollTo: "paylaterArea",
        },
      });
    },

    goToEditPayNow() {
      this.$router.push({
        name: "onboarding-form",
        params: {
          introducerRequestedId: this.userId,
          card: "productSelection",
        },
        query: {
          scrollTo: "payNowArea",
        },
      });
    },
  },
};
</script>